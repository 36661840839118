import React from 'react';
import BlockTitle from '../BlockTitle';
import OptionCard from '../OptionCard';
import './Details.scss';

const Details = ({ platforms, details, startIndex, onChange, type }) => {
  let j = 0;
  return details.map(
    (e, index) =>
      e.platforms.some(
        p => platforms.find(pl => pl.platform === p).selected
      ) && (
        <div className="details" key={index}>
          <BlockTitle index={++j + startIndex} title={e.title} />
          <div className="details-element">
            {e.variants.map((v, i) => (
              <OptionCard
                {...v}
                key={i}
                type={type}
                onChange={onChange}
                index={index}
                subindex={i}
              />
            ))}
          </div>
        </div>
      )
  );
};

export default Details;
