import { useEffect, useState, useCallback } from 'react';
import { isEmpty } from 'lodash';

const saveHistoryEndpoint = `${process.env.REACT_APP_BACKEND_HTTP_URL}/save_data/`;

const useAnalytics = ({ onSave }) => {
  const sessionId = Date.now();
  const [actionHistory, setActionHistory] = useState([]);
  const [shouldSaveHistory, setShouldSaveHistory] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-T2CRCMNW');

      function gtag() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(arguments);
      }

      window.gtag = gtag;

      (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "by8orrdtjz");
    }
  }, []);

  const getSelectedOption = useCallback(options => {
    return (options || [])
      .filter(item => item.selected)
      .map(item => item.title)
      .join(', ');
  }, []);

  const recordHistory = useCallback(
    (type, field, value, replaceExisting = false) => {
      if (!value || (typeof value === 'object' && isEmpty(value))) {
        return;
      }
      const historyItem = {
        type: type || 'input',
        field,
        value,
      };

      if (typeof window.gtag === 'function') {
        window.gtag('event', 'record_history', {
          event_category: type,
          event_label: field,
          value: value,
        });
      }

      if (field === 'estimate' || field === 'estimateFeature') {
        setShouldSaveHistory(true);
      }

      setActionHistory(a => {
        if (replaceExisting) {
          const index = a.findIndex(item => item.field === field);
          if (index !== -1) {
            a.splice(index, 1, historyItem);
            return a;
          }
        }
        return [...a, historyItem];
      });
    },
    []
  );

  const saveHistory = useCallback(() => {
    const body = {
      session_id: sessionId.toString(),
      query: {
        data: actionHistory.filter(item => item.type !== 'result'),
      },
      results: {
        data: actionHistory.filter(item => item.type === 'result'),
      },
    };
    fetch(saveHistoryEndpoint, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
      .then(() => {
        if (typeof window.gtag === 'function') {
          window.gtag('event', 'save_history', {
            event_category: 'save',
            event_label: 'history_saved',
            value: actionHistory.length,
          });
        }

        setActionHistory(s => s.filter(item => item.type !== 'result'));
      })
      .catch(() => {
        console.log('Failed to save data.');
      });
  }, [actionHistory, sessionId]);

  useEffect(() => {
    console.log('Action history', actionHistory);
    if (shouldSaveHistory) {
      saveHistory();
      setShouldSaveHistory(false);
      onSave();
    }
  }, [actionHistory, onSave, saveHistory]);

  return {
    setActionHistory,
    getSelectedOption,
    recordHistory,
    saveHistory,
  };
};

export default useAnalytics;