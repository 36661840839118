import React, { useCallback } from 'react';
import Select from '../Select';
import Image from '../Image';
import './OptionCard.scss';

const OptionCard = ({
  selected,
  title,
  icon,
  note,
  singleSelect,
  onChange,
  index,
  subindex,
  type
}) => {
  // Preload selected images (initially items is not selected).

  const handleSingleSelectClick = useCallback(() => {
    onChange(index, subindex, !selected, type);
  }, [index, onChange, selected, subindex, type]);

  return (
    <div
      className={`option-card${selected ? ' selected' : ''}`}
      onClick={handleSingleSelectClick}
    >
      {(singleSelect || selected) && <Select selected={selected} />}
      <div className="icon-title">
        <div className="icon">
          <Image src={`/icons/${icon}${!selected ? '_s' : ''}`} hidden={true} />
          <Image src={`/icons/${icon}`} />
        </div>
        <h5 className="title">{title}</h5>
      </div>
      {note && (
        <p
          className="note font--14"
          dangerouslySetInnerHTML={{ __html: note }}
        />
      )}
    </div>
  );
};

export default OptionCard;
