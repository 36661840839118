import React from 'react';

const Block = ({ title, values }) => (
  <div className="block">
    <span className="title">{title}</span>
    {values.length > 0 && (
      <span className={`item ${values.length < 2 ? '' : 'first'}`}>
        {values[0]}
      </span>
    )}
    {values.length > 1 && <span className="item-dots">...</span>}
    <div className="hover">
      <span className="title">{title}</span>
      {values.map((v, i) => (
        <span className="item" key={i}>
          {v}
        </span>
      ))}
    </div>
  </div>
);

export default Block;
