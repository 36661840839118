import React from 'react';
import './Select.scss';
import Image from '../Image';

const Select = ({ selected, platform }) => (
  <div className={`select${platform ? ' platform' : ''}`}>
    <Image src={'/icons/select'} hidden={selected} />
    <Image src={'/icons/selected'} hidden={!selected || !platform} />
    <Image src={'/icons/selected_s'} hidden={!selected || platform} />
  </div>
);

export default Select;
