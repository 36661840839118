import React, { useState, useCallback, useEffect, lazy, Suspense } from 'react';
import NoSsr from '@material-ui/core/NoSsr';
import lottieJson from '../../animation/mail-animation.json';
import validate from 'validate.js';
import './ContactForm.scss';
const Lottie = lazy(() => import('react-lottie'));

const constraints = {
  name: {
    length: {
      minimum: 3,
      tooShort: 'Please type you name'
    }
  },
  email: {
    email: {
      message: 'Please type correct email address'
    }
  }
};

const INITIAL_STATE = {
  name: '',
  email: '',
  comment: '',
  errors: {}
};

const ContactForm = ({
  sendMessage,
  formSuccess,
  formError,
  clearForm,
  isModalOpen,
  handleOpenModal
}) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(INITIAL_STATE);

  const handleClearForm = useCallback(() => {
    if (!formError) {
      setState(INITIAL_STATE);
    }
    clearForm();
  }, [clearForm, formError]);

  const handleSend = useCallback(() => {
    const errors = validate(state, constraints, { fullMessages: false });
    if (!errors) {
      const { errors: e, ...data } = state;
      setLoading(true);
      sendMessage(data);
    } else {
      setState({ ...state, errors });
    }
  }, [sendMessage, state]);

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setState(state => ({
        ...state,
        errors: { ...state.errors, [name]: null },
        [name]: value
      }));
    },
    [setState]
  );

  const handleClose = e => {
    if (e.target.classList.contains('contact-form-container')) {
      handleOpenModal(false);
    }
  };

  useEffect(() => {
    if (formSuccess || formError) {
      setLoading(false);
    }
  }, [formSuccess, formError]);

  if (loading) {
    return (
      <div
        className={`contact-form-container ${isModalOpen ? 'visible' : ''}`}
        onClick={e => handleClose(e)}
      >
        <div className="contact-form process progress">
          <div className="action-progress-dots">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>

          <h6 className="action-progress">
            We are processing your requirements, please wait...
          </h6>
        </div>
      </div>
    );
  }
  if (formSuccess) {
    const options = {
      loop: false,
      autoplay: true,
      animationData: lottieJson,
      renderSettings: {
        preserveAspectRation: 'xMidYMid slice'
      }
    };

    return (
      <div
        className={`contact-form-container ${isModalOpen ? 'visible' : ''}`}
        onClick={e => handleClose(e)}
      >
        <div className="contact-form process">
          <NoSsr>
            <Suspense fallback={<div>Loading...</div>}>
              <Lottie options={options} height={150} width={150}></Lottie>
            </Suspense>
          </NoSsr>
          <p className="action-success">
            We sent details to your email address, thank you!
          </p>
          <div className="action" onClick={() => handleOpenModal(false)}>
            Okay
          </div>
        </div>
      </div>
    );
  }
  if (formError) {
    return (
      <div
        className={`contact-form-container ${isModalOpen ? 'visible' : ''}`}
        onClick={e => handleClose(e)}
      >
        <div className="contact-form process">
          <span className="action-error">Sorry, error happend...</span>
          <div className="action" onClick={handleClearForm}>
            Try again
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      className={`contact-form-container ${isModalOpen ? 'visible' : ''}`}
      onClick={e => handleClose(e)}
    >
      <form className="contact-form" id="contact-form">
        <div className="contacts_form_desc">
          <h2 className="font--h3">
            Submit the form
            <p className="font--16">
              If you want to get detailed estimate to your email
            </p>
          </h2>
        </div>
        <div className="contacts_form_fields">
          <div className="input">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="*Name"
              value={state.name}
              onChange={handleChange}
              className={state.errors.name ? 'red' : ''}
            />
            <span data-validate-for="name" className="required__msg">
              {state.errors.name}
            </span>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="*Email"
              value={state.email}
              onChange={handleChange}
              className={state.errors.email ? 'red' : ''}
            />
            <span data-validate-for="email" className="required__msg">
              {state.errors.email}
            </span>
            <label htmlFor="comment" className="hidden">
              Provide your feedback to make our tool better
            </label>
            <input
              type="text"
              id="comment"
              name="comment"
              placeholder="Message"
              value={state.comment}
              onChange={handleChange}
            />
          </div>
          <span data-validate-for="message" className="required__msg">
            {state.errors.message}
          </span>
          <div className="btn-container">
            <div type="submit" className="btn-send" onClick={handleSend}>
              Send
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
