import React, { Fragment } from 'react';
import OptionCard from '../OptionCard';
import BlockTitle from '../BlockTitle';
import './General.scss';

const General = ({ general, onChange }) => (
  <div className="general">
    {general.map((e, index) => (
      <Fragment key={index}>
        <BlockTitle index={index + 3} title={e.title} />
        <div className="general-element">
          {e.variants.map((v, i) => (
            <OptionCard
              {...v}
              key={i}
              onChange={onChange}
              index={index}
              subindex={i}
            />
          ))}
        </div>
      </Fragment>
    ))}
  </div>
);

export default General;
