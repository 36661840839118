import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import Block from './Block';
import './Estimate.scss';

const round = v => Math.round(v.toFixed(2) * 100) / 100;
const getValues = arr => arr.filter(p => p.selected).map(p => p.title);

const Estimate = ({
  state: { platforms, general, options, specific, devDays, desDays, price },
  handleOpenModal
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  const height = isMobile ? 97 : 120;

  const handleScroll = useCallback(() => {
    const form = document.getElementById('contact-form');
    if (form) {
      handleOpenModal(true);
    }
    ReactGA.event({
      category: 'More details',
      action: 'scroll down'
    });
  }, [handleOpenModal]);

  useEffect(() => {
    setIsMobile(window.innerWidth < 650);
    setIsTablet(window.innerWidth < 821);
  }, [isMobile]);

  useEffect(() => {
    const handlePosition = () => {
      if (!isTablet) return;

      const estimator = document.querySelector('.sidebar');
      const scrollY = window.scrollY;

      if (scrollY > height) {
        estimator.style.top = '0';
      } else if (scrollY < height && scrollY > 0) {
        estimator.style.top = `${height - scrollY}px`;
      } else {
        estimator.style.top = `${height}px`;
      }
    };

    document.addEventListener('scroll', handlePosition);

    return () => document.removeEventListener('scroll', handlePosition);
  }, [height, isMobile, isTablet]);

  return (
    <div className="estimate-card" id="estimate-card">
      <h6 className="estimate-title font--h3" id="estimate-title">
        Personal estimate
      </h6>
      <div className="estimate" id="estimate">
        <div className="details">
          <Block title="Platforms" values={getValues(platforms)} />
          <Block title="App size" values={getValues(general[0].variants)} />
          <Block title="UI level" values={getValues(general[1].variants)} />
          {options.map((o, i) => (
            <Block
              key={'option' + i}
              title={o.title}
              values={getValues(o.variants)}
            />
          ))}
          {specific.map((o, i) => (
            <Block
              key={'specific' + i}
              title={o.title}
              values={getValues(o.variants)}
            />
          ))}
        </div>
        <div className="separator" />
        <div className="summary">
          <div className="hours">
            <Block title="Designer man/hours" values={[round(desDays)]} />
            <Block title="Developer man/hours" values={[round(devDays)]} />
          </div>
          <div className="price">
            <Block
              title="Total man/hours"
              values={[round(desDays + devDays)]}
            />
            <Block
              title={`Budget`}
              values={[
                '$' + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              ]}
            />
          </div>
        </div>
      </div>
      <div
        className="estimate-scroll"
        onClick={() => {
          handleScroll();
        }}
      >
        {isMobile ? 'Get estimate' : 'Get your estimate'}
      </div>
    </div>
  );
};

export default Estimate;
