import React from 'react';
import './Layout.scss';

const Layout = ({ sidebar, children }) => {
  return (
    <div className="layout">
      <div className="column">{children}</div>
      <div className="sidebar">{sidebar}</div>
    </div>
  );
};

export default Layout;
