import config from './config/schema';
const round = v => Math.round(v * 100) / 100;
const currency = v => v.toLocaleString('en', { useGrouping: true }) + '$';

export const calculate = (state, isCSV = false) => {
  const data = [],
    desPart = {};
  let rate = {};
  const header = [{ id: 'block', title: '' }];
  data.push({ block: 'Rate ' + state.rate + '$/hour' });
  const platforms = state.platforms
    .filter(p => p.selected)
    .concat({ platform: 'server', title: 'Back-end' });
  const platformsKeys = platforms.map(p => {
    if (p.platform !== 'server') {
      rate[p.platform + '_des'] = state.rate;
      header.push({ id: p.platform + '_des', title: p.title + ' (Design)' });
    }
    rate[p.platform] = state.rate;
    header.push({ id: p.platform, title: p.title });
    return p.platform;
  });

  const basic = state.general[0].variants.find(v => v.selected);
  const ui = state.general[1].variants.find(v => v.selected);
  const itemBasic = {
    block: state.general[0].note
  };
  const itemUi = {
    block: state.general[1].note
  };
  platformsKeys.forEach(key => {
    itemBasic[key] = round(basic ? basic.days[key] * 8 : 0);
    desPart[key] = round(ui ? ui.coefficient[key] : 0);
    if (key !== 'server') {
      itemUi[key + '_des'] = ui ? ui.title : 'None';
      itemBasic[key + '_des'] = round(itemBasic[key] * desPart[key]);
    }
  });
  data.push(itemBasic);
  data.push(itemUi);

  state.options.forEach(o => {
    const variants = o.variants.filter(v => v.selected);
    if (variants.length) {
      data.push({ block: o.title });
      variants.forEach(x => {
        const item = { block: x.title };
        console.log(desPart);
        platformsKeys.forEach(key => {
          item[key] = round(x.days[key]);
          if (key !== 'server') {
            item[key + '_des'] = round(item[key] * 8 * desPart[key]);
          }
        });
        data.push(item);
      });
    }
  });
  const totalDays = { block: 'Total hours' };
  const totalPrice = { block: 'Total price' };
  let desDays = 0;
  let devDays = 0;
  let summPrice = 0;
  platformsKeys.forEach(key => {
    data.forEach(e => {
      if (typeof e[key] === 'number') {
        totalDays[key] = round(totalDays[key] || 0) + e[key];
      }
      if (key !== 'server') {
        if (typeof e[key + '_des'] === 'number') {
          totalDays[key + '_des'] =
            round(totalDays[key + '_des'] || 0) + e[key + '_des'];
        }
      }
    });
  });

  state.specific.forEach(s => {
    const variants = s.variants.filter(v => v.selected);
    if (variants.length) {
      const specificItems = [];
      data.push({});
      data.push({ block: s.title });
      variants.forEach(x => {
        const item = { block: x.title };
        platformsKeys.forEach(key => {
          if (x.priceCoefficient) {
            rate[key] = rate[key] * (1 + x.priceCoefficient[key]);
            item[key] = 'Applicable';
            if (key !== 'server') {
              rate[key + '_des'] =
                rate[key + '_des'] * (1 + x.priceCoefficient[key]);
              item[key + '_des'] = 'Applicable';
            }
          }
          if (x.coefficient) {
            item[key] = round(totalDays[key] * x.coefficient[key]);
            totalDays[key] = round(totalDays[key] + item[key]);
            if (key !== 'server') {
              item[key + '_des'] = round(
                totalDays[key + '_des'] * x.coefficient[key]
              );
              totalDays[key + '_des'] = round(
                totalDays[key + '_des'] + item[key + '_des']
              );
            }
          }
        });
        specificItems.push(item);
      });
      specificItems.forEach(s => {
        data.push(s);
      });
    }
  });

  platformsKeys.forEach(key => {
    if (typeof totalDays[key] === 'number') {
      totalPrice[key] = round(totalDays[key] * rate[key]);
      summPrice += totalPrice[key];
      totalPrice[key] = currency(totalPrice[key]);
      devDays += totalDays[key];
    }
    if (key !== 'server') {
      if (typeof totalDays[key + '_des'] === 'number') {
        totalPrice[key + '_des'] = round(
          totalDays[key + '_des'] * rate[key + '_des']
        );
        summPrice += totalPrice[key + '_des'];
        totalPrice[key + '_des'] = currency(totalPrice[key + '_des']);
        desDays += totalDays[key + '_des'];
      }
    }
  });

  devDays = Math.ceil(devDays);
  desDays = Math.ceil(desDays);
  summPrice = round(summPrice);

  data.push({});
  data.push(totalDays);
  data.push(totalPrice);
  data.push({ block: 'Final hours ' + round(devDays + desDays) });
  data.push({ block: 'Final price ' + currency(summPrice) });

  if (isCSV) {
    return { header, data };
  }

  const calc = { desDays, devDays, price: summPrice };

  return { ...state, ...calc, formSuccess: false, formError: false };
};

const capitalize = function(c, i) {
  const s = c.replace(/[^a-zA-Z0-9]/g, '').replace('/', '');
  const first = s.charAt(0);
  return (
    (i === 0 ? first.toLowerCase() : first.toUpperCase()) +
    s.slice(1).toLowerCase()
  );
};

const combine = ({ title }) =>
  title
    .split(' ')
    .map(capitalize)
    .join('');

const getTitleKey = (obj, type, index) => combine(obj[type][index]);

export const extract = state => {
  const archive = {};
  archive.rate = state.rate;
  archive.platforms = state.platforms
    .filter(p => !!p.selected)
    .map(p => p.platform);
  archive.general = {};
  Object.keys(state.general).forEach(e => {
    const selected = state.general[e].variants.find(v => !!v.selected);
    if (selected) {
      archive.general[getTitleKey(state, 'general', e)] = combine(selected);
    }
  });
  ['options', 'specific'].forEach(field => {
    archive[field] = {};
    Object.keys(state[field]).forEach(e => {
      archive[field][getTitleKey(state, field, e)] = {};
      state[field][e].variants.forEach(v => {
        archive[field][getTitleKey(state, field, e)][combine(v)] = !!v.selected;
      });
    });
  });
  return archive;
};

export const restore = archive => {
  const state = JSON.parse(JSON.stringify(config));
  state.rate = archive.rate;
  state.platforms.forEach((p, i) => {
    state.platforms[i].selected = archive.platforms.includes(p.platform);
  });
  Object.keys(archive.general).forEach(e => {
    const index = state.general.findIndex(
      (v, i) => getTitleKey(state, 'general', i) === e
    );
    if (index > -1) {
      state.general[index].variants.forEach((v, i) => {
        state.general[index].variants[i].selected =
          combine(v) === archive.general[e];
      });
    }
  });
  ['options', 'specific'].forEach(field => {
    Object.keys(archive[field]).forEach(option => {
      const index = state[field].findIndex(
        (v, i) => getTitleKey(state, field, i) === option
      );
      if (index > -1) {
        Object.keys(archive[field][option]).forEach(key => {
          const subIndex = state[field][index].variants.findIndex(
            v => combine(v) === key
          );
          if (subIndex > -1) {
            state[field][index].variants[subIndex].selected =
              archive[field][option][key];
          }
        });
      }
    });
  });

  return state;
};
